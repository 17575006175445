<template>
    <div style="padding: 10px">
        <rxNavBar returnPage="TemporaryButton" :NoleftArrow="NoleftArrow" :params="testParams" title="首页测试"></rxNavBar>

        <div style="width: 100%;height: 30px;line-height: 30px">流程审批</div>
      <div>
      <!-- <button @click="click('routerMenus')" class="commonButtonStyle">跳转测试</button> -->
      <!-- <button @click="click('baiduMap')" class="commonButtonStyle">baiduMap测试</button> -->
      <button @click="click('routerMenus')" class="commonButtonStyle">付款审批</button>
      <button @click="click('staffMoreNew')" class="commonButtonStyle">新个人中心</button>
      <button @click="click('PotentialClient')" class="commonButtonStyle">实验页面</button>
      <button @click="click('dataMenuList')" class="commonButtonStyle">数据统计</button>
      <button @click="click('moreApprovalMenus')" class="commonButtonStyle">更多审批</button>

      <!-- <button @click="click('reviewOfApplyForHouse')" class="commonButtonStyle">收房</button> -->
      <button class="commonButtonStyle" @click="click('decorationApplyAudit')">装修</button>
      <!-- <button class="commonButtonStyle" @click="click('allocationAudit')">配货</button> -->
      <button @click="click('applyForChangeLease')"  class="commonButtonStyle">解除</button>
      <!-- <button class="commonButtonStyle" @click="click('applyLeaveAudit')">请假</button> -->
      <!-- <div style="display: flex;align-items: center;">
        <button @click="paymentApproval(1)"  class="commonButtonStyle">业主类</button>
        <button @click="paymentApproval(2)"  class="commonButtonStyle">渠道费</button>
      </div>
      <div style="display: flex;align-items: center;">
        <button @click="paymentApproval(3)"  class="commonButtonStyle">解除类</button>
        <button @click="paymentApproval(4)"  class="commonButtonStyle">装修款</button>
      </div>
        <div style="display: flex;align-items: center;">
          <button @click="paymentApproval(5)"  class="commonButtonStyle">租后类</button>
          <button @click="paymentApproval(15)"  class="commonButtonStyle">中后台</button>
        </div> -->
<!--              <button @click="click(path,5)"  class="commonButtonStyle">租后类</button>-->
        <!--        <button @click="click('decorationApplyAudit')"  class="commonButtonStyle">装修申请审核</button>-->

      </div>
        <!--查看页面  查看解除协议页面 没看到  （租客合同 业主 合同审核有两个页面）-->

        <div style="width: 100%;height: 30px;line-height: 30px">资源情况</div>
      <div>
        <button class="commonButtonStyle" @click="click('PotentialClient')" >潜客</button>
        <!--没看到有新增潜客页面  公客列表 现在画的不对-->
        <button @click="click('PotentialHouseList')" class="commonButtonStyle" >潜房</button>
        <button @click="click('preContract')" class="commonButtonStyle" >预定</button>
      </div>
        <div style="width: 100%;height: 30px;line-height: 30px">合同</div>
      <div>
<!--        <button class="commonButtonStyle" @click="click('preContract')">预定合同</button>-->
<!--        <button class="commonButtonStyle" @click="click('contractList')">合同</button>-->
        <button class="commonButtonStyle" @click="click('contractList')">租客合同</button>
        <button class="commonButtonStyle" @click="click('contractList')">业主合同</button>
      </div>


        <div style="width: 100%;height: 30px;line-height: 30px">租后情况</div>
        <button class="commonButtonStyle" @click="click('repairPending')">装修</button>
      <button class="commonButtonStyle" @click="click('cleanAndToDo')">保洁</button>
      <button class="commonButtonStyle" @click="click('prepareGoods')">配货(对接中)</button>
      <button class="commonButtonStyle" @click="click('maintenancePendingTreatment')">投诉</button>
      <button class="commonButtonStyle" @click="click('repairToBeAccepted')">维修</button>
        <button class="commonButtonStyle" @click="click('broadbandInstall')">宽带(未对接app)</button>
<!--        <button class="commonButtonStyle" @click="click('addAppointment')">新增预约</button>-->
<!--        <button class="commonButtonStyle" @click="click('takeALook')">带看列表</button>-->
<!--        <button class="commonButtonStyle" @click="houseDetailsClick()">房源详情点击预定合同</button>-->
<!--        <button class="commonButtonStyle" @click="houseDetailsClickSign()">房源详情点击签约合同</button>-->
<!--        <button class="commonButtonStyle" @click="houseDetailsToRenter()">房源详情点击签约</button>-->
<!--        <button class="commonButtonStyle" @click="billToCoupon()">选择优惠券（临时代替立即支付页面）</button>-->
        <button class="commonButtonStyle" @click="clearCache()">清除本地缓存</button>

        <div style="width: 100%;height: 50px"></div>
        <rxTabbar checked="home"></rxTabbar>
    </div>

</template>

<script>
    import GetLockPassword from '../../components/rongxun/rx-doorPassword/GetLockPassword'
    import rxTabbar from '../../components/rongxun/rx-tabbar/tabbar'
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    import {Button, Tabbar, TabbarItem} from 'vant';
    export default {
        components: {
            [Tabbar  .name]:Tabbar,
            [TabbarItem  .name]:TabbarItem,
            GetLockPassword,
            rxTabbar,
            rxNavBar
        },
        name: "test",

        data (){
            return{
              // path:'unPaid',
                NoleftArrow:true,
                testParams:{
                    referrerId:1,
                    referrerName:2,
                },
                ownerMobile:'18404245724',
                userName:'张女士',
                isMobileShow:false
            }
        },

        methods:{
            click:function (path) {
                this.$router.push({
                    name:path,
                    query:{
                        // roleType:'0'
                        // currentLabel:'2',
                        type:1,
                        // roomId:'5'
                    }
                })
            },
          paymentApproval(e) {
            this.$router.push({
              name: 'unPaid',
              query: {
                type:e
              }
            })
          },
            showMobile(){
                let that = this;
                that.isMobileShow = !that.isMobileShow;
            },

            clearCache(){
                //清除本地localStorage缓存
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
                let currentLabel = JSON.parse(localStorage.getItem('currentLabel'));
                let type = JSON.parse(localStorage.getItem('type'));
                let inputValue = JSON.parse(localStorage.getItem('inputValue'));
                let currentLabelPublic = JSON.parse(localStorage.getItem('currentLabelPublic'));
                if((currentLabel == null && type == null) && (currentLabelPublic == null && inputValue == null)) {
                    alert("清除成功")
                }
            },

            hideDialog() {
                // alert("值过来了！！")
                let that = this;
                that.isMobileShow = false;
            },

            houseDetailsClick(){
                this.$router.push({
                    name:'newReserve',
                    query:{
                        roomId: '5',
                        type: '1'
                    }
                })
            },
            houseDetailsClickSign(){
                this.$router.push({
                    name:'NewContract',
                    query:{
                        roomId: '1',
                        type: '1',
                        roomDetailedAddress:'前程街-前程街9号3单元503号-A间'
                    }
                })
            },
            houseDetailsToRenter(){
                this.$router.push({
                    name:'NewContract',
                    query:{
                        roomId: '1',
                        roomDetailedAddress: '',
                        type: '1'
                    }
                })
            },

            billToCoupon(){
                this.$router.push({
                    name:'myDiscountCoupon',
                    query:{
                        type: '1'
                    }
                })
            },

            // // 跳转装修申请审核
            // routerDecorationAudit:function () {
            //     this.$router.push({name:'decorationApplyAudit',params:{id:123}})
            // },
            // // 跳转收房申请审核
            // applyHouseAuditClick:function () {
            //     this.$router.push({
            //         name:'reviewOfApplyForHouse',
            //         params:{
            //             id:123}
            //     })
            // },
            // // 跳转潜房
            // contractListClick:function () {
            //     this.$router.push({
            //         name:'contractList',
            //         params:{
            //             id:123}
            //     })
            // },
            //
            // // 跳转合同
            // PotentialHouseListClick:function () {
            //     this.$router.push({
            //         name:'PotentialHouseList',
            //         params:{
            //             id:123}
            //     })
            // },
            //
            // //配货
            // distribution:function () {
            //     this.$router.push({
            //         name:'prepareGoods',
            //     })
            // },

        }
    }


</script>

<style scoped>
    .commonButtonStyle{
        width: 90%;
        height: 60px;
        border:2px red solid;
        border-radius: 20px;
        margin: 20px 20px;
    }
</style>
